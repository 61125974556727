import React from 'react';

function Contact() {
  return (
    <div>
      <h1>Contact</h1>
      <p>Contact Page</p>
    </div>
  );
}

export default Contact;
